export default function scrollTo(to, duration, offset) {
  const element = document.scrollingElement || document.documentElement;
  const start = element.scrollTop;
  offset = offset || 0;
  to = offset ? offset + to : to;
  const change = to - start;
  const startDate = +new Date();

  const easeInOutQuad = (time, start, change, duration) => {
    time /= duration / 2;
    if (time < 1) return change / 2 * time * time + start;
    time--;
    return -change / 2 * (time * (time - 2) - 1) + start;
  };

  const animateScroll = () => {
    const currentDate = +new Date();
    const currentTime = currentDate - startDate;

    element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration));
    currentTime < duration ? requestAnimationFrame(animateScroll) : element.scrollTop = to;
  };

  animateScroll();
}
