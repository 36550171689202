import scrollTo from '../helpers/scrollTo';

export default class ScrollToLink {
  constructor(linkSelector, dest) {
    this.element = document.querySelectorAll(linkSelector);

    Array.prototype.forEach.call(this.element, link => {
      link.addEventListener('click', (e) => {
        e.preventDefault();

        console.log('asd');

        let destination = dest === 'next' ? link.closest('section').clientHeight : dest === 'prev' ? (link.closest('section').clientHeight * -1) : document.querySelector(link.dataset.dest).offsetTop;
        scrollTo(destination, 400);
      }, false);
    });
  }

  get() {
    return this.element;
  }
}
