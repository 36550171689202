import '../styles/style.scss';

//3rd party
import './helpers/jquery'
import 'slick-carousel/slick/slick';

//custom
import './helpers/vh';
import './component/menu';
import './component/video';

//images
import "../images/desk.jpg";
import "../images/author.jpg";
import "../images/magnified.jpg";
import ScrollToLink from "./component/scrollto";

//initializations

let heroScroller = new ScrollToLink('.section-hero .arrow', 'next');

let $slider = $('.slider');
$slider
  .on('init', function () {
    $slider.removeClass('d-none');
  })
  .slick({
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplaySpeed: 6000,
    autoplay: true,
    // adaptiveHeight: true,
    fade: $slider.hasClass('slider-fade'),
    speed: 500,
    draggable: false,
    appendDots: $slider.parents('.slider-wrapper').find('.slider-dots') || '',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          autoplay: false,
          speed: 300,
          draggable: true
        }
      }
    ]
  });
