let player;
let playerWrapper = document.querySelector('#player-wrapper');
let playerClose = document.querySelector('#player-close');

function onPlayerReady(event) {
  let playerElement = document.querySelector('#player');

  let buttons = document.querySelectorAll('.phone .btn-play');
  buttons.forEach(el => {
    let vid = el.dataset.video;
    el.addEventListener('click', () => {
      player.loadVideoById(vid);
      player.playVideo();
      playerWrapper.classList.add('active');
    });
  });

  playerClose.addEventListener('click', () => {
    playerWrapper.classList.remove('active');
    player.stopVideo();
  });

  document.addEventListener('webkitfullscreenchange', fullscreenChange);
  document.addEventListener('mozfullscreenchange', fullscreenChange);
  document.addEventListener('fullscreenchange', fullscreenChange);
  document.addEventListener('MSFullscreenChange', fullscreenChange);

  function fullscreen(vid) {
    if (document.fullscreenEnabled) {
      player.loadVideoById(vid);
      playerElement.requestFullscreen();
    } else {
      console.error('Your browser is not supported');
    }
  }

  function fullscreenChange() {
    if (document.fullscreenElement) {
      console.log('enter fullscreen');
      player.playVideo();
    } else {
      if (document.exitFullscreen) {
        console.log('exit fullscreen');
        player.stopVideo();
      }
    }
  }
}

function onPlayerStateChange(event) {
  playerWrapper.classList[event.data <= 0 || event.data === 5 ? 'remove' : 'add']('active');
}

window.onYouTubeIframeAPIReady = () => {
  player = new YT.Player('player', {
    height: '1920',
    width: '1080',
    videoId: 'ScMzIvxBSi4',
    playerVars: {
      'autoplay': 0,
      'controls': 1,
      'fs': 0,
      'rel': 0,
      'origin': window.location.origin,
      'iv_load_policy': 3,
      'showinfo': 0
    },
    events: {
      'onReady': onPlayerReady,
      'onStateChange': onPlayerStateChange
    },
  });
};

let tag = document.createElement('script');
tag.src = "https://www.youtube.com/iframe_api";
let firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

